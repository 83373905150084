import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import styles from './ProductsList.module.scss';
import { animateScroll, stopCardAnimation } from '../ProductDetailsCard/cardAnimation';
//todo: don't include those resources in bundle
import SRPStyles from '../SearchResultsPage/SearchResultsPage.module.scss';
import ProductStyles from '../Product/Product.module.scss';
import getShopsList from '../../utils/getShopsList';
import constructURL from '../../utils/constructURL';
import useMediaQuery from '../../utils/useMediaQuery';
import { useProductDetails } from './useProductDetails';
import { getNodePageY } from '../../utils/getNodePageY';
import { VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';
// import clickoutScript from '../../utils/clickoutScript';

import ProductsListItems from './ProductsListItems';
import ProductVideo from '../ProductVideo/ProductVideo';
import loadable from '@loadable/component';
import { clipTextEllipsis } from '../../utils/textEllipsis';
import { isFeatureEnabled } from '../../features/SwitchableFeature/SwitchableFeature';
import {
  PRODUCT_CARD_TO_PDP,
  PRODUCT_CARD_TO_PDP_LINK,
  PRODUCT_CARD_WITH_PDC,
} from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import getCDNImage from '../../utils/getCDNImage';
import { PRODUCT_SEARCH_GRID } from '../Product/ProductCardOriginType.def';

const Product = loadable(() => import('../Product/Product'));
//todo: exclude this one from this bundle too, we don't need PDC on initial load while not requested
const ProductDetailsCard = loadable(() => import('../ProductDetailsCard/ProductDetailsCard'));

const getProductFromSearchData = (searchData, productHash) => {
  const searchDataReady = searchData && productHash;
  const searchDataItemIndex = searchDataReady && searchData.hits.hits.findIndex((item) => item._id === productHash);
  const selectedProductIndex = searchDataItemIndex === -1 ? null : searchDataItemIndex;
  const searchDataItem = searchData.hits.hits[selectedProductIndex];

  if (!searchDataItem) return null;

  const source = searchDataItem?._source;
  return {
    ...source,
    id: searchDataItem._id,
    itemIndex: selectedProductIndex,
    EAN: source.offers[0].EAN,
    originalPrice: source.price_original || 0,
    shopId: source.shop_id.toString(),
    url: source.offers[0].url,
    position: selectedProductIndex,
    shops: getShopsList([source]),
  };
};

const initialViewState = {
  isOpening: false, //open card with animation
  isClosing: false, //close card with animation
  isOpened: false, //render card already opened
};

//Card actions. Used in productsListReducer and in ProductsList useEffect hook
const OPEN_CARD_INSTANTLY = 'OPEN_CARD_INSTANTLY';
const OPEN_CARD_SMOOTHLY = 'OPEN_CARD_SMOOTHLY';
const SWITCH_CARDS_INSTANTLY = 'SWITCH_CARDS_INSTANTLY';
const SWITCH_CARDS_SMOOTHLY = 'SWITCH_CARDS_SMOOTHLY';
const CLOSE_CARD_SMOOTHLY = 'CLOSE_CARD_SMOOTHLY';
const CARD_RESET = 'CARD_RESET';

const initialPLState = {
  apiData: { shopInfoData: null, shopsDataBody: null },
  isSameRowClicked: false,
  lastClickedRowCoords: { offsetYTop: null, offsetYBottom: null },
  onClosed: () => {},
  selectedProduct: '',
  viewState: initialViewState,
};

const PLReducer = (state, action) => {
  switch (action.type) {
    case OPEN_CARD_SMOOTHLY:
      return {
        ...state,
        apiData: action.apiData,
        isSameRowClicked: action.isSameRowClicked,
        lastClickedRowCoords: action.lastClickedRowCoords,
        onClosed: action.onClosed,
        selectedProduct: action.selectedProduct,
        viewState: action.viewState,
      };
    case SWITCH_CARDS_INSTANTLY:
      return {
        ...state,
        apiData: action.apiData,
        isSameRowClicked: action.isSameRowClicked,
        selectedProduct: action.selectedProduct,
        viewState: action.viewState,
      };
    case SWITCH_CARDS_SMOOTHLY:
      return {
        ...state,
        onClosed: action.onClosed,
        viewState: action.viewState,
      };
    case CLOSE_CARD_SMOOTHLY:
      return {
        ...state,
        onClosed: action.onClosed,
        viewState: action.viewState,
      };
    case OPEN_CARD_INSTANTLY:
      return {
        ...state,
        selectedProduct: action.selectedProduct,
        apiData: action.apiData,
        viewState: action.viewState,
      };
    default:
      return initialPLState;
  }
};

const ProductsList = React.memo(
  ({
    classList,
    view = VIEW_OPTIONS_TILES,
    products,
    isDesktop,
    selectedProductHash,
    searchData,
    videos,
    config,
    page,
    isFashionView,
  }) => {
    const { push } = useHistory();
    const location = useLocation();

    const [productTarget, setProductTarget] = useState(null);
    const [onReadyAction, setOnReadyAction] = useState(null);
    const [state, dispatch] = useReducer(PLReducer, initialPLState);
    const [cookies] = useCookies(['enabledFeatureFlags']);
    const { isGreaterOrEqualTo: atLeast, BREAKPOINTS } = useMediaQuery();
    const { MEDIUM: tabletBP, EXTRA_EXTRA_LARGE: desktopBP } = BREAKPOINTS;

    const isSelectedProductPresent = !!products.find((item) => item.id === state.selectedProduct);
    const setCardOpened = () => {
      dispatch({
        type: OPEN_CARD_INSTANTLY,
        selectedProduct: selectedProductHash,
        apiData,
        viewState: { ...initialViewState, isOpened: true },
      });
    };
    //todo: load pdc more efficiently
    const createDetailsCard = (product, apiData, viewState, classList) => (
      <ProductDetailsCard
        key={`productDetailsCard_${product.id}`}
        classList={{ ...classList, [SRPStyles.productDetailsCard]: true }}
        isDesktop={isDesktop}
        onClose={isDesktop ? closeCardSmoothly : closeCard}
        onClosed={state.onClosed}
        onOpened={setCardOpened}
        product={product}
        view={view}
        {...apiData}
        viewState={viewState}
      />
    );
    const isClickedOnTheSameRow = ({ pageY }) => {
      //bottom and top coordinates are the offsetY from the document top
      const { offsetYBottom, offsetYTop } = state.lastClickedRowCoords;
      return pageY <= offsetYBottom && pageY >= offsetYTop;
    };

    const setSelectedProductHash = (id) => {
      if (isDesktop) {
        push(constructURL(`?sel=${id}`)(location));
      } else {
        push(constructURL(`?sel=${id}#${id}`)(location));
      }
    };

    const dropSelectedProductHash = () => {
      if (isDesktop) {
        push(constructURL('?sel=')(location));
      } else {
        push(constructURL(`?sel=#`, { ignoreExistingHash: true })(location));
      }
    };

    function closeCard() {
      //Then CARD_RESET will be dispatched from useEffect to work with location changes
      dropSelectedProductHash();
    }

    //returns node where page will be scrolled to
    const getScrollToNode = (productNode) => {
      //todo: excessive usage
      if (isFashionView) return productNode.querySelector(`.${ProductStyles.productName}`);
      else return productNode;
    };

    const openCardInstantly = (apiData) => {
      const selectedProductNode = document.getElementById(selectedProductHash);
      const scrollToNode = getScrollToNode(selectedProductNode);
      animateScroll(scrollToNode);
      dispatch({
        type: OPEN_CARD_INSTANTLY,
        selectedProduct: selectedProductHash,
        apiData,
        viewState: { ...initialViewState, isOpened: true },
      });
    };

    //Opens new card with animation
    const openCardSmoothly = (productCardNode, selectedProduct, apiData) => {
      const offsetTop = getNodePageY(productCardNode);
      const { height } = productCardNode.getBoundingClientRect();

      dispatch({
        type: OPEN_CARD_SMOOTHLY,
        apiData,
        isSameRowClicked: false,
        lastClickedRowCoords: { offsetYTop: offsetTop, offsetYBottom: offsetTop + height },
        onClosed: closeCard,
        selectedProduct,
        viewState: { ...initialViewState, isOpening: true },
      });
    };

    //Opens new card without animation when it's loaded
    const switchOpenedCardsInstantly = (productCardNode, selectedProduct, apiData) => {
      dispatch({
        type: SWITCH_CARDS_INSTANTLY,
        apiData,
        isSameRowClicked: true,
        selectedProduct,
        viewState: { ...initialViewState, isOpened: true },
      });
    };
    //Opens new and closes previous with animation
    const switchOpenedCardsSmoothly = (productNode, selectedProduct, apiData) => {
      dispatch({
        type: SWITCH_CARDS_SMOOTHLY,
        viewState: { ...initialViewState, isClosing: true },
        onClosed: () => {
          const scrollToNode = getScrollToNode(productNode);
          animateScroll(scrollToNode);
          openCardSmoothly(productNode, selectedProduct, apiData);
        },
      });
    };

    //Closes single card with animation
    const closeCardSmoothly = () => {
      dispatch({
        type: CLOSE_CARD_SMOOTHLY,
        viewState: { ...initialViewState, isClosing: true },
        onClosed: closeCard,
      });
    };

    const onProductClick = (productId, pdpPath) => {
      if (isFeatureEnabled(cookies, config, PRODUCT_CARD_TO_PDP_LINK)) return;

      if (isFeatureEnabled(cookies, config, PRODUCT_CARD_TO_PDP)) {
        return openPDPOnClick(pdpPath);
      } else return openPDCOnClick(productId, pdpPath);
    };

    const openPDPOnClick = (pdpPath) => () => {
      push(pdpPath);
    };

    const openPDCOnClick = (productId, pdpPath) => (e) => {
      if (!isDesktop) {
        //open PDP on mobile devices and tablet
        push(pdpPath);
        //Open external offer link
        // const clkurl = clickoutScript(offerUrl, clickout, true);
        // window.open(clkurl, '_blank');
        return;
      }
      const sameRow = isClickedOnTheSameRow(e);
      stopCardAnimation();
      const currentTarget = e.currentTarget;
      const scrollToNode = getScrollToNode(currentTarget);
      const sameProduct = productId === state.selectedProduct;
      setProductTarget(currentTarget);
      if (!sameProduct) {
        setSelectedProductHash(productId);
      }
      if (sameProduct) {
        closeCardSmoothly(currentTarget);
      } else if (sameRow) {
        if (isDesktop) animateScroll(scrollToNode);
        setOnReadyAction(SWITCH_CARDS_INSTANTLY);
      } else if (!state.selectedProduct || !isSelectedProductPresent) {
        if (isDesktop) animateScroll(scrollToNode);
        setOnReadyAction(OPEN_CARD_SMOOTHLY);
      } else {
        setOnReadyAction(SWITCH_CARDS_SMOOTHLY);
      }
    };
    const columnsView = view === VIEW_OPTIONS_COLUMNS;
    const tilesView = view === VIEW_OPTIONS_TILES;
    const tilesLayout = 'col-6-xs col-4-m col-3-xxl';
    const productLayout = columnsView ? 'col-12-xs col-6-m' : tilesLayout;

    const productsInViewPort = () => {
      const isDesktop = atLeast(desktopBP);
      const isTablet = atLeast(tabletBP);

      if (isDesktop && tilesView) return 10;
      else if (isDesktop && columnsView) return 4;
      else if (isTablet && tilesView) return 6;
      else if (isTablet && columnsView) return 4;
      else if (tilesView) return 4;
      else return 2;
    };
    const isInViewPort = (productNumber) => productNumber < productsInViewPort();
    //Render
    const productsListView = products.map((product, index) => {
      const columnsViewDescLength = 280;
      const tilesViewDescLength = 100;
      const clipDescAt = columnsView ? columnsViewDescLength : tilesViewDescLength;
      const shortDesc = product.shortDesc && clipTextEllipsis(product.shortDesc, clipDescAt);
      const productImage = product.thumbs?.[0]?.url || getCDNImage(product.thumbs?.[0]);
      //todo: to reduce props count, pass product object to a component
      //and then make sure api returns proper object type
      return (
        <Product
          adult={product?.adult}
          shopRating={product.shopRating?.rating}
          classList={{ layout: productLayout }}
          currency={product.currency}
          shortDesc={shortDesc}
          price={product.price}
          originalPrice={product.price_original || product.price}
          key={product.id}
          //This one has nothing to do with hash from PDP
          id={product.id} //todo: make sure product id's are really consistent over the app
          image={productImage}
          isSelected={state.selectedProduct && product.id === state.selectedProduct}
          name={product.name}
          onClick={onProductClick(product.id, product.canonical_path || product.pdp_path)}
          shop={{ name: product.campaign }}
          shopId={product?.shop_id}
          merchantPid={product?.merchant_product_id}
          url={product.canonical_path || product.pdp_path}
          view={view}
          status={product.new}
          isFashionView={isFashionView}
          showProductLink={isFeatureEnabled(cookies, config, PRODUCT_CARD_WITH_PDC)}
          isInViewPort={isInViewPort(index)}
          showShopInfo
          resultRank={index}
          category={product?.category}
          productCardOriginType={PRODUCT_SEARCH_GRID}
        />
      );
    });
    const video = page === 1 && videos?.length && videos?.[0];
    const videoProps = !!video?.videoUrl && {
      videoUrl: video.videoUrl,
      title: video.title,
      description: video.description,
    };
    const skipProducts = videoProps ? 2 : 0;
    const videoItem = videoProps && <ProductVideo key={'videocard'} view={view} {...videoProps} />;

    const trimmedProducts =
      skipProducts && productsListView.length > 2
        ? productsListView.slice(0, productsListView.length - 2)
        : productsListView;
    const productsListViewWithVideo = videoProps ? [videoItem, ...trimmedProducts] : trimmedProducts;

    const currentProductObject = getProductFromSearchData(searchData, state.selectedProduct);
    const hashProductObject = getProductFromSearchData(searchData, selectedProductHash);

    const shopInfoData =
      (!!hashProductObject && {
        shop_name: hashProductObject.campaign,
        shop_logo: hashProductObject.shopLogo,
        shop_website: hashProductObject.sdp_path,
      }) ||
      undefined;

    const { dataReady, shopsDataBody } = useProductDetails(hashProductObject, searchData?.search_id);
    const offline = false; //FIXME: add somewhere to useProductDetails when PWA will be implemented
    const currentDataReady = !!(
      (state.apiData.shopInfoData || state.apiData.shopsDataBody || offline) &&
      currentProductObject
    );
    const apiData = { shopInfoData, shopsDataBody };
    useEffect(() => {
      if (dataReady || offline) {
        switch (onReadyAction) {
          case OPEN_CARD_SMOOTHLY:
            openCardSmoothly(productTarget, selectedProductHash, apiData);
            break;
          case SWITCH_CARDS_INSTANTLY:
            switchOpenedCardsInstantly(productTarget, selectedProductHash, apiData);
            break;
          case SWITCH_CARDS_SMOOTHLY:
            switchOpenedCardsSmoothly(productTarget, selectedProductHash, apiData);
            break;
          default:
            if (selectedProductHash)
              openCardInstantly({
                shopInfoData:
                  (!!hashProductObject && {
                    shop_name: hashProductObject.campaign,
                    shop_logo: hashProductObject.shopLogo,
                    shop_website: hashProductObject.sdp_path,
                  }) ||
                  undefined,
                shopsDataBody,
              });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataReady, selectedProductHash]);
    //todo: load pdc more efficiently
    const detailsCard = currentDataReady
      ? createDetailsCard(currentProductObject, state.apiData, state.viewState)
      : null;

    const PLIClassName = classnames(styles.root, classList.root, 'row', {
      [styles.viewOptionColumns]: view === VIEW_OPTIONS_COLUMNS,
      [styles.viewOptionTiles]: view === VIEW_OPTIONS_TILES,
    });

    useEffect(() => {
      if (!selectedProductHash.trim()) dispatch({ type: CARD_RESET });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProductHash]);

    return (
      <ProductsListItems
        selectedProductIndex={currentProductObject?.itemIndex}
        productsList={productsListViewWithVideo}
        detailsCard={detailsCard}
        classList={PLIClassName}
        offline={offline}
        isTilesView={view === VIEW_OPTIONS_TILES}
        isVideoPresent={!!videoProps}
      />
    );
  }
);

ProductsList.displayName = 'ProductsList';

ProductsList.propTypes = {
  //view: PropTypes.oneOf([VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES]),
  classList: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  selectedProductHash: PropTypes.string.isRequired,
};

const mapStateToProps = ({ config }) => ({
  config,
});

export default connect(mapStateToProps)(ProductsList);
